import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { getMerchantDetails, signUpSchool } from "src/api";
import { PRODUCT_INFO, SCHOOL_TYPES, STATES } from "src/utils/constants";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { MailIcon, PhoneIcon } from "src/assets/svg";
import { CustomInput, CustomSelect } from "src/components";
import { renderAlert } from "src/utils/functions";
import { Helmet } from "react-helmet";

const SignUp = () => {
	const searchParams = new URLSearchParams(window.location.search);

	const merchant = searchParams.get("merchant") ?? "schoolrevs";
	const merchantInfo = PRODUCT_INFO[merchant] ?? PRODUCT_INFO["schoolrevs"];

	const { data } = useQuery({
		queryKey: ["merchant", merchant],
		queryFn: () => getMerchantDetails({ action: "get_merchant_by_name", merchant }),
		onSuccess: ({ data }) => {
			var r = document.querySelector(":root");
			r.style.setProperty("--primary-color", data?.color || "#743790");
			r.style.setProperty("--secondary-color", data?.accent_color || "#0d3060");
		},
		suspense: true,
	});
	const merchantData = data?.data;

	const initialValues = {
		action: "register_school",
		packageID: merchantData?.package_id,
		schoolName: "",
		address: "",
		city: "",
		state: "",
		email: "",
		mobile: "",
		level: "",
		merchantID: merchantData?.id,
		agree: false,
	};

	const validationSchema = Yup.object().shape({
		schoolName: Yup.string().required("This field is required"),
		address: Yup.string().required("This field is required"),
		city: Yup.string().required("This field is required"),
		state: Yup.string().required("This field is required"),
		email: Yup.string().email("Please provide a valid email").required("This field is required"),
		mobile: Yup.string().required("This field is required"),
		level: Yup.string().required("This field is required"),
		merchantID: Yup.number().required("No merchant provided"),
		packageID: Yup.number().required("No package provided"),
		agree: Yup.boolean().isTrue("You must agree to the terms and conditions before continuing"),
	});

	const { mutate, isLoading } = useMutation(signUpSchool, {
		onSuccess: ({ message, data }) =>
			renderAlert({ msg: message, title: "Success", type: "success" }).then(() => {
				window.location.href = data;
			}),
	});

	return (
		<div className="max-w-[1400px] mx-auto p-8">
			<Helmet>
				<meta charSet="utf-8" />
				<title>{merchantData?.name} Subscription Signup</title>
				<meta name="description" content={`Signup page for ${merchantData?.name} subscriptions`} />
				<link rel="icon" href={`https://core.revocube.com/uploads/merchants/${merchant}.png`} />
			</Helmet>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-[5rem] min-h-[calc(100vh-5rem)]">
				<div className="my-[1.5rem]">
					<img src={`https://core.revocube.com/uploads/merchants/${merchant}.png`} className="h-[50px]" alt="" />
					<div className="my-[5rem] border border-primary rounded-[1rem] py-[3rem] px-[4rem] shadow-[0px_8px_40px_0px_rgba(0,0,0,0.05)]  lg:border-0 lg:rounded-none lg:py-0 lg:px-0 lg:shadow-none">
						<p className="text-[2.8rem] leading-[3.36rem] font-semibold text-primary ">School Registration</p>
						<p className="text-[1.5rem] leading-[3.36rem] font-medium text-gray mb-[2.5rem]">{merchantData?.name}</p>
						<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
							{({ values, handleChange, handleSubmit, setFieldValue }) => (
								<form onSubmit={handleSubmit} className="grid gap-6">
									<CustomInput label="School / Institution" name="schoolName" value={values.schoolName} onChange={handleChange} />
									<CustomInput label="Address" name="address" value={values.address} onChange={handleChange} />
									<CustomInput label="City" name="city" value={values.city} onChange={handleChange} />
									<CustomSelect
										label="State"
										name="state"
										placeholder=""
										options={STATES}
										onChange={(value) => setFieldValue("state", value.value)}
									/>
									<CustomInput label="Admin Email" name="email" value={values.email} onChange={handleChange} />
									<CustomInput label="Mobile No." name="mobile" value={values.mobile} onChange={handleChange} />
									<CustomSelect
										label="Select school type"
										name="level"
										placeholder=""
										options={SCHOOL_TYPES}
										onChange={(value) => setFieldValue("level", value.value)}
									/>
									<div>
										<label className="text-[1.4rem] flex items-center text-[#334155] py-4 " htmlFor="agree">
											<input
												type="checkbox"
												checked={values.agree}
												onChange={(event) => setFieldValue("agree", event.target.checked)}
												className="mr-3 accent-primary w-[1.7rem] h-[1.7rem] cursor-pointer"
												name="agree"
												id="agree"
											/>
											<span>
												I agree with {merchantInfo.name}'s{" "}
												<a href="/x" className="text-primary hover:underline">
													Terms of use
												</a>{" "}
												and{" "}
												<a href="/x" className="font-medium text-primary hover:underline">
													Privacy Policy
												</a>
											</span>
										</label>
										<ErrorMessage name="agree" component="div" className="block text-[1.2rem] mt-2 text-red-500" />
									</div>
									<ErrorMessage name="merchantID" component="div" className="block text-[1.2rem] mt-2 text-red-500" />
									<ErrorMessage name="packageID" component="div" className="block text-[1.2rem] mt-2 text-red-500" />
									<button className="primary-btn btn-loader" disabled={isLoading}>
										Sign Up
									</button>
									<div className="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 md:mx-auto">
										<div className="flex items-center gap-2 text-[#4E5D6D] text-[1.6rem] font-medium">
											<MailIcon />
											support@schoolrevs.com
										</div>
										<div className="flex items-center gap-2 text-[#4E5D6D] text-[1.6rem] font-medium">
											<PhoneIcon />
											+234-705-394-4592
										</div>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
				<div className="bg-primary rounded-[3rem] hidden lg:block bgImage p-[6rem]">
					<p className="text-white font-semibold text-[4rem] my-[4rem] tracking-tight">{merchantInfo.header}</p>
					<p className="text-white font-normal text-[1.6rem]">{merchantInfo.subtitle}</p>
				</div>
			</div>
			<p className="text-gray text-[1.6rem] font-medium text-center leading-[2.2rem] my-[3rem]">
				© Copyright {new Date().getFullYear()} Revocube SchoolRevs. All Rights Reserved
			</p>
		</div>
	);
};

export default SignUp;

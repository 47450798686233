import React from "react";

const Loader = (props) => {
	return (
		<div className="flex items-center justify-center" style={{ width: "100%", height: "10vh" }}>
			<span className="spinner"></span>
		</div>
	);
};

export default Loader;

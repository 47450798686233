import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SignUp } from "./pages";
import "./App.css";
import { Loader } from "./components";

const router = createBrowserRouter([
	{
		path: "/",
		element: <SignUp />,
	},
]);

function App() {
	return (
		<Suspense fallback={<Loader />}>
			<RouterProvider router={router} />
		</Suspense>
	);
}

export default App;

import axios from "axios";
import { renderErrorMessage } from "./utils/functions";

const api = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
	headers: {
		"Content-Type": "multipart/form-data",
	},
});

api.interceptors.response.use(
	(response) => response.data,
	(error) => {
		renderErrorMessage(error.response.data.message);
		throw new Error(error.response.data.message);
	}
);

export const getMerchantDetails = (params) => api.get("", { params });

export const signUpSchool = (data) => api.post("", data);
